import type { RequestEvent } from '@sveltejs/kit';

import * as Sentry from '@sentry/sveltekit';

import { v4 as uuid } from '@lukeed/uuid';
import { PUBLIC_APP_URL } from '$env/static/public';

const isProduction = PUBLIC_APP_URL === 'https://edu.institem.com';

export const initSentry = () => {
	if (!isProduction) return;

	Sentry.init({
		dsn: 'https://861bd160510c23681dc5b95f284bbe74@o4505641323593728.ingest.sentry.io/4505641327984640',
		tracesSampleRate: 1.0 // Capture 100% of the transactions, reduce in production!
	});
};

export const handleErrorWithSentry = ({
	error,
	event
}: {
	error: unknown;
	event: RequestEvent;
}) => {
	if (!isProduction) return;

	const id = uuid();

	Sentry.captureException(error, {
		contexts: {
			sveltekit: { event, id }
		}
	});

	return { id };
};
